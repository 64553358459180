.navbar{
  .small-btn {
    line-height: 15px;
    .btn-header {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
    }
    .btn-sub {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}

.navbar-dark .navbar-nav .nav-link{
  color: rgba(255, 255, 255, 0.5);
}

.text-green {
  color: #00af72!important;
}

.bg-green {
  background-color: #00af72;
}

.text-dark {
  color: #2b2b2b;
}

.bg-dark {
  background-color: #2b2b2b;
}

.text-blue {
  color: #0f6cff;
}

.bg-blue {
  background-color: #0f6cff;
}

.text-red {
  color: #ff1800;
}

.text-grey {
  color: #899195;
}

.stars.checked {
  color: #ffcb4a;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 175, 114, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

// Typography

.header1 {
  font-weight: 500;
  font-size: 55px;
  line-height: 65px;
}

@media (max-width: 992px) {
  .header1 {
    font-size: 40px;
    line-height: 50px;
  }
}

.jumbotron {
  background-color: #eaeaea;
}

.notify-widget {
  position: fixed;
  display: flex;
  bottom: 75px;
  left: 15px;
  width: 300px;
  padding: 10px 15px;
  background-color: #eaeaea;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  z-index: 1000;

  .mark {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #00af72;
    border-radius: 100%;
    margin-right: 10px;
  }

  .name, .membership-type {
    font-weight: 600;
  }
}

// Video player
.unauthorized-panel {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: #151515;
    color: white;
    top: 0;
    margin: auto;
    padding: 10px;

   span {
      max-width: 390px;
      text-align: center;
   }
}

.video-description {
  padding: 10px;
}

.bg-dark {
  .video-description {
    color: white;
  }
}

/* Video list */
.video-list {
  max-height: 100vh;
  overflow: auto;
  /* width */
  &::-webkit-scrollbar,
  {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 20px solid #00af72;
  border-bottom: 8px solid transparent;
}
