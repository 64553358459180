a {
	cursor: pointer;
}
.pointer {
	cursor: pointer;
}

.-mb-2 {
	margin-bottom: -0.5em !important;
}

.attachment {
	.img_wrapper {
		position: relative;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.img_wrapper.with-header {
		.attachment-head {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1.9em;
			background-color: #777;
			color: #FFF;
			white-space: nowrap;
			overflow: hidden;
			padding: 0.2em 0.3em;
			border-radius: 0.4em 0.4em 0 0;
		}
	}
	.rel {
		position: relative;
	}
	.img_wrapper.at-tp-2 {
		.play-ico-cont {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -1em;
			text-align: center;
			width: 100%;
			display: inline-block;
		}
		.play-ico {
			font-size: 3em;
			color: #FFF;
		}
		.img-bg-cont {
			text-align: center;
		}
	}
	.img-bg-cont {
		display: inline-block;
		width: 12em;
		height: 12em;
		border: 1px solid #f0f0f0;
		background-color: #fafafa;
		margin-right: 0.3em;
		margin-bottom: 0.3em;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		.bg-cont-inner {
			display: inline-block;
			position: absolute;
			top: 5%;
			left: 5%;
			width: 90%;
			height: 85%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}
	.img-bg-cont.ico {
		text-align: center;
		position: relative;
	}
	.img-bg-cont.file {
		input[type="file"] {
			width: 0;
			height: 0;
			overflow: hidden;
			opacity: 0;
		}
	}
	.error-ico {
		font-size: 2.5em;
		color: red;
	}
}
.progress-bar-block {
	.progress-bar-cnt {
		border: 1px solid #888;
		display: inline-block;
		height: 0.6em;
		width: 10em;
		position: relative;
		text-align: center;
		.progress-bar {
			position: absolute;
			left: 0;
			top: 0;
			background-color: green;
			height: 100%;
			width: 0;
		}
	}
	.progress-bar-cnt.aborted {
		.progress-bar {
			background-color: grey;
		}
	}
	.progress-bar-cnt.failed {
		.progress-bar {
			background-color: red;
		}
	}
}
.attachment-form {
	.input-wrapper {
		.ico.file {
			input[type=file] {
				width: 0;
				height: 0;
				overflow: hidden;
				position: absolute;
				left: 0;
				top: 0;
			}
			display: inline-block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			.file-rep.lab-ico {
				display: inline-block;
				position: absolute;
				width: 100%;
				text-align: center;
				left: 0;
				top: 0;
				height: 100%;
				cursor: pointer;
				.plus {
					color: #999;
					font-size: 3em;
				}
			}
		}
	}
	.abs-center {
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		margin-top: -1.4em;
	}
	.new-item-block {
		display: inline-block;
		vertical-align: middle;
	}
	.img-wrapper {
		margin-right: 0.6em;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		img {
			width: 100%;
		}
	}
	.processing {
		text-align: center;
		.img-cont {
			width: 2em;
		}
		.proc-wrapper {
			display: inline-block;
			vertical-align: middle;
			font-size: 1em;
		}
	}
	.rm-attach {
		position: absolute;
		top: 0;
		right: 0.5em;
		z-index: 1;
		color: #888 !important;
		padding: 0.2em;
	}
}
input.replace-btn-by-label {
	width: 0;
	height: 0;
	opacity: 0;
	overflow: hidden;
}
.v-align-middle {
	&:before {
		content: "";
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	}
}
.v-align-middle-item {
	display: inline-block;
	vertical-align: middle;
}
.attachment-comp {
	position: relative;
}
.vertical-center-gr {
	.vcg-item {
		display: inline-block;
		vertical-align: middle;
	}
	.vcg-item.h-space {
		margin: 0 0.5em;
	}
}
.forum-overall-wrapper {
	#main_container {
		padding-right: 0;
		padding-left: 0;
		.breadcrumb {
			margin-left: 0;
		}
	}
}
nav.navbar {
	&:not(.gsa-nav) {
		display: none !important;
	}
}
nav.breadcrumb-wrapper {
	background-color: #e9ecef;
	.breadcrumb {
		vertical-align: middle;
		width: 62%;
		max-width: 62%;
		display: inline-flex;
		overflow-x: hidden;
		margin-bottom: 0;
	}
	.form-search {
		display: inline-flex;
		vertical-align: middle;
	}
}
.att-modal-content {
	width: 100%;
	height: 100%;
	.att-modal-wrapper {
		width: 100%;
		height: 100%;
		text-align: center;
		.att-cont {
			width: 100%;
			height: 100%;
		}
		.att-image-cont {
			width: 100%;
			height: 100%;
			.img-bg {
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				display: inline-block;
				width: 100%;
				height: 100%;
				min-height: 20em;
				min-width: 45em;
			}
		}
		.att-video-cont {
			width: 100%;
			height: 100%;
			video {
				width: 100%;
			}
		}
	}
}
.reveal-overlay.forum-overlay-preview {
	text-align: center;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	min-height: 100vh;
	background-color: rgba(0,0,0,0.9);
	&:before {
		display: inline-block;
		content: "";
		height: 100%;
		vertical-align: middle;
		width: 0;
	}
}
#attachment-view.reveal {
	vertical-align: middle;
	display: inline-block;
	width: auto;
	height: auto;
	top: 0 !important;
	position: static !important;
}
#attachment-view.reveal.img-orient-horizontal {
	max-width: 90vw;
	width: auto;
}
#attachment-view.reveal.img-orient-vertical {
	max-height: 80vh;
	height: auto;
}
#attachment-view.reveal.img-orient-vertical.img-orient-overflow {
	height: 100%;
}
#attachment-view.reveal.img-orient-horizontal.img-orient-overflow {
	width: 100%;
}
.card.post {
	background-color: rgba(255,255,255,1);
	transition: background-color 1s ease;
}
.card.post.indent {
	margin-left: 4em;
}
.card.post.highlight-me {
	background-color: rgba(0,255,255,1);
}
img {
	max-width: 100%;
}
.yt-widget {
	max-width: 100%;
	iframe {
		max-width: 100%;
	}
}
.controls-link-wrapper {
	padding-right: 1rem !important;
}
