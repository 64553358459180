
::v-deep .vue-treeselect__option-arrow-container {
  display: none;
}

::v-deep .vue-treeselect__option-arrow-placeholder {
  display: none;
}

::v-deep .vue-treeselect__control {
  font-size: 20px;
}

::v-deep .vue-treeselect__indent-level-0 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-2 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-3 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__indent-level-4 .vue-treeselect__option {
  padding-left: 0;
}

::v-deep .vue-treeselect__control {
  display: none;
}
::v-deep .vue-treeselect__menu::-webkit-scrollbar {
  display: none;
}

::v-deep .vue-treeselect__checkbox-container {
  display: none;
}

::v-deep .vue-treeselect__option--highlight {
  background: transparent;
}

::v-deep .vue-treeselect__option {
  padding: 0;
  &:hover {
    background: transparent;
  }
}
