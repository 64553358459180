/*!
 * BootstrapVue Custom CSS (https://bootstrap-vue.js.org)
 */
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 2.6em;
  height: 2.6em;
  font-size: 0.88em;
  line-height: 1;
  margin: 0.24em auto;
  padding: 0.73em 0;
}


.card-img-left {
  border-top-left-radius: calc(0.25em - 1px);
  border-bottom-left-radius: calc(0.25em - 1px);
}

.card-img-right {
  border-top-right-radius: calc(0.25em - 1px);
  border-bottom-right-radius: calc(0.25em - 1px);
}

.b-dropdown-form {
  padding: 0.25em 1.5em;
}

.b-dropdown-form:focus {
  outline: 0.3em auto -webkit-focus-ring-color !important;
}

.b-dropdown-text {
  padding: 0.25em 1.5em;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 1.25em;
  padding-left: 1.875em;
}

.custom-checkbox.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-checkbox .custom-control-label::before {
  top: 0.3125em;
  left: -1.875em;
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.3em;
}

.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
  top: 0.3125em;
  left: -1.875em;
  width: 1.25em;
  height: 1.25em;
}

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.875em;
  line-height: 1.5;
  padding-left: 1.3125em;
}

.custom-checkbox.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-checkbox .custom-control-label::before {
  top: 0.21875em;
  left: -1.3125em;
  width: 0.875em;
  height: 0.875em;
  border-radius: 0.2em;
}

.custom-checkbox.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-checkbox .custom-control-label::after {
  top: 0.21875em;
  left: -1.3125em;
  width: 0.875em;
  height: 0.875em;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 2.8125em;
}

.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 1.25em;
}

.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  top: 0.3125em;
  height: 1.25em;
  left: -2.8125em;
  width: 2.1875em;
  border-radius: 0.625em;
}

.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: 0.4375em;
  left: -2.6875em;
  width: 1em;
  height: 1em;
  border-radius: 0.625em;
}

.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(0.9375em);
  transform: translateX(0.9375em);
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 1.96875em;
}

.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
  font-size: 0.875em;
}

.custom-switch.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-switch .custom-control-label::before {
  top: 0.21875em;
  left: -1.96875em;
  width: 1.53125em;
  height: 0.875em;
  border-radius: 0.4375em;
}

.custom-switch.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-label::after {
  top: calc( 0.21875em + 0.125em);
  left: calc( -1.96875em + 0.125em);
  width: calc( 0.875em - 0.25em);
  height: calc( 0.875em - 0.25em);
  border-radius: 0.4375em;
}

.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(0.65625em);
  transform: translateX(0.65625em);
}


.b-form-datepicker.form-control.focus {
  box-shadow: 0 0 0 0.2em rgba(0, 123, 255, 0.25);
}

.b-form-datepicker.form-control.focus.is-valid {
  box-shadow: 0 0 0 0.2em rgba(40, 167, 69, 0.25);
}

.b-form-datepicker.form-control.focus.is-invalid {
  box-shadow: 0 0 0 0.2em rgba(220, 53, 69, 0.25);
}

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 1.25em;
  height: 2.4em;
}

.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.5em 1em;
  line-height: 1.5;
}

.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
  border-radius: 0.3em;
}

.b-custom-control-lg .custom-file-label::after,
.input-group-lg .custom-file-label::after {
  height: 2.5em;
  border-radius: 0 0.3em 0.3em 0;
}

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.875em;
  height: 2.17em;
}

.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.25em 0.5em;
  line-height: 1.5;
}

.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0.2em;
}

.b-custom-control-sm .custom-file-label::after,
.input-group-sm .custom-file-label::after {
  font-size: inherit;
  height: 2em;
  border-radius: 0 0.2em 0.2em 0;
}

.was-validated .form-control:invalid,
.was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(0.375em + 0.1875em) center;
}

input[type="color"].form-control {
  height: calc(1.5em + 0.75em + 0.1em);
  padding: 0.125em 0.25em;
}

input[type="color"].form-control.form-control-sm,
.input-group-sm input[type="color"].form-control {
  height: calc(1.5em + 0.5em + 0.1em);
  padding: 0.125em 0.25em;
}

input[type="color"].form-control.form-control-lg,
.input-group-lg input[type="color"].form-control {
  height: calc(1.5em + 1em + 0.1em);
  padding: 0.125em 0.25em;
}


.input-group > .custom-range {
  height: calc(1.5em + 0.75em + 0.1em);
  padding: 0 0.75em;
  height: calc(1.5em + 0.75em + 0.1em);
  border-radius: 0.25em;
}

.input-group > .custom-range:focus {
  box-shadow: 0 0 0 0.2em rgba(0, 123, 255, 0.25);
}

.input-group-lg > .custom-range {
  height: calc(1.5em + 1em + 0.1em);
  padding: 0 1em;
  border-radius: 0.3em;
}

.input-group-sm > .custom-range {
  height: calc(1.5em + 0.5em + 0.1em);
  padding: 0 0.5em;
  border-radius: 0.2em;
}
.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
  box-shadow: 0 0 0 0.2em rgba(40, 167, 69, 0.25);
}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2em #9be7ac;
}

.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2em #9be7ac;
}

.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2em #9be7ac;
}


.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
  box-shadow: 0 0 0 0.2em rgba(220, 53, 69, 0.25);
}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2em #f6cdd1;
}

.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2em #f6cdd1;
}

.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2em #f6cdd1;
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 1.25em;
  padding-left: 1.875em;
}

.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: 0.3125em;
  left: -1.875em;
  width: 1.25em;
  height: 1.25em;
}

.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: 0.3125em;
  left: -1.875em;
  width: 1.25em;
  height: 1.25em;
}

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.875em;
  padding-left: 1.3125em;
}

.custom-radio.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-radio .custom-control-label::before {
  top: 0.21875em;
  left: -1.3125em;
  width: 0.875em;
  height: 0.875em;
}

.custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  top: 0.21875em;
  left: -1.3125em;
  width: 0.875em;
  height: 0.875em;
}


.b-form-spinbutton.form-control.focus {
  box-shadow: 0 0 0 0.2em rgba(0, 123, 255, 0.25);
}

.b-form-spinbutton.form-control.focus.is-valid {
  box-shadow: 0 0 0 0.2em rgba(40, 167, 69, 0.25);
}

.b-form-spinbutton.form-control.focus.is-invalid {
  box-shadow: 0 0 0 0.2em rgba(220, 53, 69, 0.25);
}

.b-form-tags.focus {
  box-shadow: 0 0 0 0.2em rgba(0, 123, 255, 0.25);
}

.b-form-tags.focus.is-valid {
  box-shadow: 0 0 0 0.2em rgba(40, 167, 69, 0.25);
}

.b-form-tags.focus.is-invalid {
  box-shadow: 0 0 0 0.2em rgba(220, 53, 69, 0.25);
}

.b-pagination-pills .page-item .page-link {
  border-radius: 50em !important;
  margin-left: 0.25em;
}

.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 1em;
}

.b-table-sticky-header {
  max-height: 18.75em;
}


.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.75em / 2) center;
  padding-right: calc(0.75em + 0.65em);
}

.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.75em / 2) center;
  padding-left: calc(0.75em + 0.65em);
}


.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3em / 2) center;
  padding-right: calc(0.3em + 0.65em);
}

.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3em / 2) center;
  padding-left: calc(0.3em + 0.65em);
}

@media (max-width: 575.98px) {
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
    padding: 0 calc(1em / 2) 0 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
    padding: 0 0 0 calc(1em / 2);
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
    border-top-width: 0.22em;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
    border-top-width: 0.22em;
  }
}

@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    padding: 0 calc(1em / 2) 0 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    padding: 0 0 0 calc(1em / 2);
  }
  .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 0.22em;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
    border-top-width: 0.22em;
  }
}

@media (max-width: 991.98px) {
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    padding: 0 calc(1em / 2) 0 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    padding: 0 0 0 calc(1em / 2);
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
    border-top-width: 0.22em;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
    border-top-width: 0.22em;
  }
}

@media (max-width: 1199.98px) {
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
    padding: 0 calc(1em / 2) 0 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
    padding: 0 0 0 calc(1em / 2);
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
    border-top-width: 0.22em;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
    border-top-width: 0.22em;
  }
}

.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  padding: 0 calc(1em / 2) 0 0;
}

.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  padding: 0 0 0 calc(1em / 2);
}

.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 0.22em;
}

.table.b-table.b-table-stacked > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
  border-top-width: 0.22em;
}

.b-toast {
  max-width: 21.87em;
  border-radius: 0.25em;
}

.b-toast:not(:last-child) {
  margin-bottom: 0.75em;
}

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  left: 0.5em;
  right: 0.5em;
}

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  max-width: 21.87em;
}

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 0.5em;
}

.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: 0.5em;
}

.tooltip.b-tooltip .arrow {
  margin: 0 0.25em;
}

.tooltip.b-tooltip.bs-tooltip-right .arrow, .tooltip.b-tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.bs-tooltip-left .arrow, .tooltip.b-tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow {
  margin: 0.25em 0;
}

/*# sourceMappingURL=bootstrap-vue.css.map */