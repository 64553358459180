
// The code below needed to fix overflow-y: hidden for swiper.js
::v-deep .swiper-container {
  overflow: visible;
  z-index: 20;
}

::v-deep .swiper-wrapper {
  z-index: 10;
}

::v-deep .swiper-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
}

::v-deep .swiper-slide-active {
  opacity: 1;
  visibility: visible;
}

::v-deep .swiper-slide-active + .swiper-slide {
  opacity: 1;
  visibility: visible;
}

::v-deep .swiper-slide-active + .swiper-slide + .swiper-slide {
  opacity: 1;
  visibility: visible;
}

::v-deep .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide {
  opacity: 1;
  visibility: visible;
}

